import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/hero.png";
import thumbnailEmpWellness from "../../static/images/emp_wellness.jpeg"
import gifLineChart from "../../static/images/animated_icons/line-chart/animat-linechart-color.gif"
import gifCust from "../../static/images/animated_icons/customize/animat-customize-color.gif"
import gifLightbulb from "../../static/images/animated_icons/lightbulb/animat-lightbulb-color.gif"
import gifPencil from "../../static/images/animated_icons/pencil/animat-pencil-color.gif"
import gifRocket from "../../static/images/animated_icons/rocket/animat-rocket-color.gif"
import thumbnailBoard from "../../static/images/abstract_2.jpg"
import thumbnailNews from "../../static/images/abstract_3.jpg"
import thumbnailTeams from "../../static/images/abstract_4.jpg"
import thumbnailStaff from "../../static/images/hero.png"
import purple from "../../static/images/purple.png"
import asparagus from "../../static/images/asparagus.png"
import broccoli from "../../static/images/broccoli.png"

const IndexPage = () => (
    <Layout>
        <SEO title="Nutrition on your schedule"/>

        <div className={"page-header home"}>
          <div className={"hero-tile"}>
            <h1>{"Personalized, dedicated wellness for your employees."}</h1>
            <p>{"Recurring sessions with Registered Dietitians offered as part of your benefits program."}</p>
            <div className={"get-started"}>
              <a href={"https://moodfoodtech.typeform.com/to/XdDzF1fe"}>{"Request Demo"}</a>
            </div>
          </div>
        </div>

        <div className={"quotation"}>
          <div className={"container"}>
            <div className={"features"}>
              <div className={"feature__item"}>
                <div className={"row"}>
                  <div className={"tiles col-4 first"}>
                    <div className="tile-text">
                      <h1>{"Empower"}</h1>
                      <span>{"MoodFood empowers companies to better manage mental health, wellness and burnout among their employees."}</span>
                    </div>
                    <div className={"thumbnail"}>
                      <img alt={"Surveys"} src={asparagus}/>
                    </div>
                  </div>
                  <div className={"tiles col-4"}>
                    <div className={"tile-text"}>
                      <h1>{"Improve"}</h1>
                      <span>
                        {"We connect employees to Registered Dietitians to improve their quality of life and enable them to feel and perform their best."}
                      </span>
                    </div>
                    <div className={"thumbnail"}>
                      <img alt={"Matching"} src={purple}/>
                    </div>
                  </div>
                  <div className={"tiles col-4"}>
                    <div className={"tile-text"}>
                      <h1>{"Support"}</h1>
                      <span>
                        {"Our team of science experts craft customized plans for each employee that include nutrition, mindfulness and exercise counseling."}
                      </span>
                    </div>
                    <div className={"thumbnail"}>
                      <img alt={"Improve"} src={broccoli}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"main-background"}>
          <div className={"container main-content"}>
              <div className={"features"}>
                  <div className={"feature__item"}>
                      <div className={"row"}>

                          <div className={"col-6 first"}>
                              <div className={"feature__content"}>
                                  <h2>Help your team where it matters</h2>
                                  <p>Diet and nutrition are a major source of stress for professionals of all ages</p>
                              </div>
                          </div>
                          <div className={"col-6"}>
                              <div className={"thumbnail"}>
                                  <img alt={"Event"} src={gifCust}/>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className={"feature__item"}>
                      <div className={"row"}>
                          <div className={"col-6 first"}>
                              <div className={"thumbnail"}>
                                  <img alt={"News"} src={gifLightbulb}/>
                              </div>
                          </div>

                          <div className={"col-6"}>
                              <div className={"feature__content"}>
                                  <h2>{"More relevant than ever"}</h2>
                                  <p>With the changes to how work is performed in 2020, whether remote or in person, nutrition supports physical as well as mental health</p>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className={"feature__item"}>
                      <div className={"row"}>
                          <div className={"col-6"}>
                              <div className={"feature__content"}>
                                  <h2>Flexible and Convenient</h2>
                                  <p>{"Our system allows each enrolled team member to privately schedule and connect with a registered dietitian at their own pace and in their own time."}</p>
                              </div>
                          </div>

                          <div className={"col-6 first"}>
                              <div className={"thumbnail"}>
                                  <img alt={"Team"} src={gifLineChart}/>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className={"feature__item"}>
                      <div className={"row"}>
                          <div className={"col-6 first"}>
                              <div className={"thumbnail"}>
                                  <img alt={"Users"} src={gifPencil}/>
                              </div>
                          </div>

                          <div className={"col-6"}>
                              <div className={"feature__content"}>
                                  <h2>{"Differentiate your company"}</h2>
                                  <p>Companies offering new and unusual employee benefits see that effort paid back in retention, engagement, and ease of hiring.</p>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className={"feature__item"}>
                      <div className={"row"}>
                          <div className={"col-6"}>
                              <div className={"feature__content"}>
                                  <h2>Flexible and Convenient</h2>
                                  <p>{"Our system allows each enrolled team member to privately schedule and connect with a registered dietitian at their own pace and in their own time."}</p>
                              </div>
                          </div>

                          <div className={"col-6 first"}>
                              <div className={"thumbnail"}>
                                  <img alt={"Team"} src={gifRocket}/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>Sign up for free</h2>
                    <p>Sign up and start providing best in class benefits to your employees with MoodFood</p>
                </div>

                <div className={"button"}>
                    <a href="" target={"_blank"}>Get Started</a>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
